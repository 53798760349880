import {Injectable, OnDestroy} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, interval, Observable, ReplaySubject, Subject} from 'rxjs';

import {ApiService} from './api.service';
import {AccessJson, Auth, LicenseModel, NewOrganizationModel, OrganizationModel} from '@amlCore/models';
import {Router} from "@angular/router";
import {VisitedService} from "./visited.service";
import {debounceTime, delay, distinctUntilChanged, takeUntil, tap} from "rxjs/operators";
import moment from "moment";
import {CryptographService, CryptoValidataService} from "../../cryptography";
import {Utils} from "@amlCore/utils";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {ValidatorService} from "./validator.service";
import {UnblockDocumentsService} from "./unblock-documents.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AuthWsService, JwtService, UserSessionService, WebSocketService} from "frontend-auth-ws";
import {AlertService} from "../components/alert/alert.service";


@Injectable({providedIn: "root"})
// TODO нужно рефакторить, добавить геттеры, сеттеры для приватных св-в,
//  добавить проверки в методы, чтобы потом покрыть тестами
export class UserService implements OnDestroy{

    LICENSE_INVALID = 'Отсутствует действующая лицензия или количество активных пользователей превышает установленное в лицензии количество. Часть функций системы недоступна, обратитесь к администратору системы.';
    LICENSE_EXPIRED_DAY = 14;
    LICENSE_EXPIRED_DATE = `До окончания срока действия лицензии осталось менее ${this.LICENSE_EXPIRED_DAY} дней.`;

    private authenticateUser = {} as Auth;

    private currentUserSubject = new BehaviorSubject<Auth>({} as Auth);
    currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

    private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
    userActions$ = new Subject<string>()
    private destroy$ = new Subject()
    get getIsAuthenticatedSubject(): ReplaySubject<boolean> {
      return this.isAuthenticatedSubject;
    }

    isAuthenticated = this.isAuthenticatedSubject.asObservable();
    showMenu$ = new BehaviorSubject(!!sessionStorage.getItem('Auth-Token')?.length);
    private accessListSubject = new ReplaySubject<Array<AccessJson>>(1);
    accessList = this.accessListSubject.asObservable();
    certMessageErrorSubject = new ReplaySubject<{ type: 'warn' | 'error', message: string }>(1);
    certMessageError = this.certMessageErrorSubject.asObservable();

    private orgInfoSubject = new BehaviorSubject<{firstInit: boolean} | NewOrganizationModel>({firstInit: true});
    // distinctUntilChanged - сравниваем элементы, вызываем событие если оно отличается от предыдущего
    orgInfo = this.orgInfoSubject.asObservable().pipe(distinctUntilChanged());

    constructor(private apiService: ApiService,
                private http: HttpClient,
                private jwtService: JwtService,
                private router: Router,
                private visitedService: VisitedService, private cryptographSrv: CryptographService, private cryptoValidataSrv: CryptoValidataService,
                private readonly fb: UntypedFormBuilder,
                private readonly validationSrv: ValidatorService,
                private userSession: UserSessionService,
                private webSocketService: WebSocketService,
                private unblockDocuments: UnblockDocumentsService,
                private readonly modalService: NgbModal,
                private authWsService: AuthWsService,
                private alertService: AlertService) {
    }

    get getAuthenticateUser(): Auth {
      return this.authenticateUser;
    }

    set setAuthenticateUser(authData: Auth) {
      this.authenticateUser = authData;
    }

    /**
     * Проверка авторизации и Заполнение профиля
     * Запускается один раз при инициализации приложения
     */
    tryAuth(): void {
        if (this.jwtService.getToken()) {
            this.apiService.get('/api/v1/authad/')
            .subscribe(
              (data: Auth) => {
                const authData = {...data, ...this.userSession.getUserSession()}
                if (authData.needChangePassword) {
                  this.router.navigateByUrl('/changePassword');
                }
                this.setAuth(authData);
              },
              err => this.clearAuth()
            );
        } else {
            this.clearAuth();
        }
    }

    isExpiredPassword(id: string): Observable<{ error: string }> {
      return this.apiService.get(`/api/v1/authad/users/isExpiredPassword/${id}`)
    }

    logout(): Observable<void> {
        sessionStorage.removeItem('orgName');
        sessionStorage.removeItem('orgInn');
        sessionStorage.removeItem('orgBik');
        sessionStorage.removeItem('userRole');
        return this.apiService.post('/api/v1/authad/logout', {tokenId: this.jwtService.getToken()});
    }

    setSelectedCert(auth: Auth) {
      if (auth?.cert?.certThumb && auth?.roles?.find( r => r.id === 'cryptography')) {
        this.cryptographSrv.isCryptEnable.subscribe((state) => {
            if (state.state === false) {
                this.certMessageErrorSubject.next(
                    {
                        type: 'error',
                        message: this.checkCryptInstall(state)
                    })
                return;
            }
          this.cryptographSrv.crypt.getCertsFromUser(auth.cert.certThumb).then(value => {
            if (value?.length && value.length === 1) {
              this.cryptographSrv.crypt.selectedCert(value[0].certCAPICOM)
            } else {
                this.certMessageErrorSubject.next(
                    {
                        type: 'warn',
                        message: `В профиле пользователя не назначен сертификат ключа проверки электронной подписи, или выбранный сертификат не валиден!`
                    })
            }
          }, error => {
              if (error.message?.indexOf('0x000004C7') > 0) {
                  this.certMessageErrorSubject.next({
                      type: 'error',
                      message: `Операция была отменена пользователем. Подпись документов будет невозможна!`
                  })
              }
          });
        });
      } else {
        this.cryptographSrv.crypt.resetSelectedCert();
      }
    }

    initValidata(auth: Auth) {
      if (['cryptography', 'reestr_rejection'].every(id => auth?.roles?.some( r => r.id === id))) {
        this.cryptoValidataSrv.isCryptEnable.subscribe((state) => {
            if (state.state === false) {
                this.certMessageErrorSubject.next(
                    {
                        type: 'error',
                        message: state.textError
                    })
                return;
            }
            if (!this.cryptoValidataSrv.crypt.getSelectedCert() ) {
                this.certMessageErrorSubject.next(
                    {
                        type: 'warn',
                        message: `В профиле пользователя не назначен сертификат ключа проверки электронной подписи, или выбранный сертификат не валиден!`
                    })
            }
        });
        this.cryptoValidataSrv.init();
      }
    }

    /**
     * Выставление авторизации
     */
    setAuth(auth: Auth): void {
        this.authenticateUser = auth;
        this.accessListSubject.next(auth.roles);

        // Выставление текущего пользователя
        this.currentUserSubject.next(auth);
        // Выставляем статус авторизации как положительный
        this.isAuthenticatedSubject.next(true);
        this.authWsService.setAuthLib(auth)
        this.initOrgInfo();
        this.setSelectedCert(auth);
        this.initValidata(auth);
        localStorage.setItem('userInfo', JSON.stringify({fio: auth.fio}));

        /* если в ls нет признака закрепленного меню,
         * то выставляем отображение меню по умолчанию в положение закрепленное
         */
        if (sessionStorage.getItem(`isPinnedMenuNav-${this.authenticateUser.login}`) === null) {
            sessionStorage.setItem(`isPinnedMenuNav-${this.authenticateUser.login}`, "true");
        }


        this.webSocketService.isDestroySessionObs.pipe(
            takeUntil(this.destroy$)
        ).subscribe((data) => {
            this.saveDocumentsAndLogout()
        })

        this.webSocketService.alertMessageObs.pipe(
            debounceTime(2000),
            takeUntil(this.destroy$)
        ).subscribe((data) => {
            this.alertService.error(data.message, undefined, undefined, data.timer)
        })

        this.userActions$.pipe(
            debounceTime(1000),
            takeUntil(this.destroy$)
        ).subscribe(() => {
            if (!this.userSession.checkIsUserIdle() && this.userSession.getUserSession().sessionId) {
                this.userSession.resetUserTimer()
            }
        })

        interval(1000).pipe(
            takeUntil(this.destroy$)
        ).subscribe(() => {
            if (this.userSession.checkIsUserIdle() && this.userSession.getUserSession().sessionId && this.jwtService.getToken()) {
                this.logout().subscribe(() => this.saveDocumentsAndLogout())
            }
        })

        interval(60000).pipe(
            takeUntil(this.destroy$)
        ).subscribe(() => {
            this.userSession.isNeedUpdateToken() && this.refreshToken().subscribe(({tokenId, refreshToken, expiresAt}) => {
                this.jwtService.saveToken(tokenId, refreshToken)
                this.userSession.setExpiresToken(expiresAt)
            })
        })
    }
    changeActiveCert(cert): Auth {
        this.authenticateUser = {...this.authenticateUser, cert};
        this.currentUserSubject.next(this.authenticateUser);
        return this.authenticateUser;
    }

    /**
     * Очистка авторизации
     */
    clearAuth(routeToLogin?: boolean): void {
        this.visitedService.clearVisitedList();
        // Выставляем пустого пользователя
        this.currentUserSubject.next({} as Auth);
        // Выставляем статус авторизации как ложный
        this.isAuthenticatedSubject.next(false);
        this.authWsService.clearAuthLib()
        this.authenticateUser = {} as Auth;
        this.accessListSubject.next([]);

        if (routeToLogin) {
            this.router.navigateByUrl('/login');
        }
    }

    /**
     * Пробуем авторизироваться
     */
    attemptAuth(auth = {}, authMethod: 'caml' | 'ldap'): Observable<any> {
        return this.apiService.post(authMethod ==='caml' ? '/api/v1/authad/' : '/api/v1/authad/ldap', auth, '', '', true)
          .pipe(tap(data => {
              const authData = {...data.body, sessionId: data.headers.get('Session-Id'), sessionTimeOut: data.headers.get('Session-Timeout') * 60}
              this.setAuth(authData)
          }));
    }

    generateTempPassUser(userId: string): Observable<void> {
      return this.apiService.get(`/api/v1/authad/users/generateTempPass/${userId}`);
    }

    getCurrentUser(): Auth {
        return this.currentUserSubject.value;
    }

    checkAccess(ids: any): boolean {
        const result = this.getAccess(ids);
        return result === null
            ? false
            : Boolean(result);
    }

    getAccess(ids: any): AccessJson {
      return this.authenticateUser.roles && this.authenticateUser.roles.length === 0
        ? null
        : this.authenticateUser.roles?.find(
          (item) => typeof ids !== "string"
            ? ids.find(id => item.id === id)
            : item.id === ids);
    }

    getStartPageUser(auth: Auth): string {
        if (auth.tokenId) {
            return 'admin/userList';
        }
    }

    /**
     * Получить информацию по лицензии
     */
    getLicense(): Observable<LicenseModel> {
        return this.apiService.get('/api/v1/authad/profile/license');
    }

    /**
     * Загрузить информацию по организации
     */
    loadOrgInfo(): Observable<NewOrganizationModel> {
        return this.apiService.get('/api/v1/authad/profile/orginfo');
    }

    /**
     * Получить информацию по организации
     */
    saveOrgInfo(data: NewOrganizationModel): Observable<NewOrganizationModel> {
        return this.apiService.put('/api/v1/authad/profile/orginfo', data);
    }

    /**
     * Инициализация или обновление данных о лицензии
     */
    initOrgInfo(): void {
        this.loadOrgInfo().subscribe((response: NewOrganizationModel) => {
            this.orgInfoSubject.next(response);
            sessionStorage.setItem('orgName', response.name);
            sessionStorage.setItem('orgInn', response.tin);
            sessionStorage.setItem('orgBik', response.bic);
        });
    }

    /**
     * Проверка лицензии, выводим сообщение:
     * В случае отсутствия лицензии или просроченной лицензии
     * В случае когда осталось менее {LICENSE_EXPIRED_DAY} дней до окончания
     */
    checkLicense(orgInfo: NewOrganizationModel): string | null {
        if (typeof orgInfo === 'object' && orgInfo.hasOwnProperty('firstInit')) {
            return null;
        }
        if (orgInfo && orgInfo.license) {
            if (orgInfo.license.valid) {
                const nowPlus14 = moment().add(this.LICENSE_EXPIRED_DAY, 'days');
                const endDate = moment(orgInfo.license.ends, "YYYY-MM-DD");
                if (nowPlus14.isAfter(endDate)) {
                    return this.LICENSE_EXPIRED_DATE;
                } else {
                    // Лицензия есть и срок корректный
                    return null;
                }
            } else {
                return this.LICENSE_INVALID;
            }
        } else {
            return this.LICENSE_INVALID;
        }
    }

    /*метод получения данных по текущему пользователю в системе*/
    getUserInfo(): { fio: string } {
        return JSON.parse(localStorage.getItem('userInfo'));
    }

    changePassword(param: any): Observable<void> {
        return this.apiService.post("/api/v1/authad/users/changePassword", param);
    }

    checkTransactionExecutor(param: any, transactionId: string): Observable<any> {
        return this.apiService.post(`/api/v1/transaction/check/executor/${transactionId}`, param);
    }

    checkClientExecutor(param: any, checkClientId: string): Observable<any> {
        return this.apiService.post(`/api/v1/check/client/executor/${checkClientId}`, param);
    }

    checkNoticeExecutor(param: any, noticeId: string): Observable<any> {
        return this.apiService.post(`/api/v1/notices/executor/${noticeId}`, param);
    }
    checkCryptInstall(state) {
        if (state.state === false) {
            return this.cryptographSrv.getMessageAfterInit(state)
        }
        return '';
    }

    checkLDAPEnabled() {
        return this.apiService.get(`/api/v1/authad/ldap/enabled`);
    }

    getUserForm(): UntypedFormGroup {
        return this.fb.group({
            id: [''],
            userRole: ['', this.validationSrv.getValidation({
                isReq: true
            })],
            loginName: ['', this.validationSrv.getValidation({
                max: 100,
                isReq: true
            })],
            fullName: ['', this.validationSrv.getValidation({
                max: 150,
                isReq: true
            })],
            phone: [''],
            position: ['', this.validationSrv.getValidation({
                max: 60,
            })],
            department: ['', this.validationSrv.getValidation({
                max: 100,
            })],
            email: ['', this.validationSrv.getValidation({
                max: 60,
                pattern: (Utils.emailPattern),
                isReq: true
            })]
        });
    }

    // AML-2546 - Решение временного разделения Создания и Редактирования пользователя в рамках переработки UI
    getNewUserForm(): UntypedFormGroup {
        return this.fb.group({
            id: [''],
            userRole: ['', this.validationSrv.getValidation({
                isReq: true
            })],
            loginName: [''],
            fullName: ['', this.validationSrv.getValidation({
                max: 150,
                isReq: true
            })],
            position: [''],
            department: [''],
            email: [''],
            phone: ['']
        })
    }

    get accessRoutesMap() {
        const accessRoutesMap = {
            //Документооборот
            'arm_fm' : 'cabinet/fm/drafts',
            'svetofor' : 'cabinet/svetofor/drafts',
            'reestr_rejection': 'cabinet/rejection/income',
            'requests-government-agencies': 'cabinet/requestsga/unstruct',
            'arm_4937_u' : 'cabinet/arm4937/drafts',
            'oes-otkaz-goz' : 'cabinet/strateg5392u/drafts',
            'arm_strateg' : 'cabinet/strateg655p/drafts',
            'arm_407': 'cabinet/elMsgFm/drafts',
            //Перечни
            'qref_terror' : 'cabinet/terrorists',
            'qref_fromu' : 'cabinet/fromuInfoList',
            'qref_mvk' : 'cabinet/mvkInfoList',
            'qref_otkaz' : 'cabinet/bounceRegisterAcceptedList',
            'qref_mslead' : 'cabinet/massLeadInfo',
            'qref_msfound' : 'cabinet/massFoundInfo',
            'qref_msadr' : 'cabinet/massAdrInfo',
            'qref_fts' : 'cabinet/ftsInfo',
            'qref_passp': 'cabinet/passportInfo',
            'qref_internalorg' : 'cabinet/internalList',
            'cls_ctfb_5016' : 'cabinet/fstateInfo',
            'cls_ctfb' : 'cabinet/paycardInfo',
            'qref_riskgroup': 'cabinet/riskGroupInfo',
            'spk': 'cabinet/customer-check',
            //Факторы риска
            'risk_config' :  'cabinet/riskfactorInfo',
            'risk_classes' :  'cabinet/riskfactorClasses/factors',
            'risk_classes_operations': 'cabinet/riskfactorClasses/operations',
            //Досье
            'svedClient': 'dossier/list',
            'risk_view' : 'dossier/check',
            'transactionslist' : 'clients-transactions',
            'transactionchecklist' : 'check-transactions',
            'notices' : 'dossier/notice',
        };
        return accessRoutesMap;
    }

    saveDocumentsAndLogout(): void {
        const dossierId = window.sessionStorage.getItem('dossierId')
        const documentId = window.sessionStorage.getItem('documentId')
        this.modalService.dismissAll(true)
        if (dossierId) {
            this.unblockDocuments.unblockDossierAfterCloseSession().subscribe(() => {
                this.clearAuth()
            })
        } else if (documentId) {
            this.unblockDocuments.unblockDocumentsAfterCloseSession().subscribe(() => {
                this.clearAuth()
            })
        } else {
            this.clearAuth()
        }
    }

    refreshToken() : Observable<Pick<Auth, "tokenId" | "refreshToken" | "expiresAt">> {
        return this.apiService.post('api/v1/authad/refresh', {refreshToken: this.jwtService.getRefreshToken()})
    }

    ngOnDestroy(): void {
        this.destroy$.next(true)
        this.destroy$.complete()
    }
}
