import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import * as directives from "./directives";
import * as components from "./components";
import {UserComponent} from "./components";
import * as services from "./services";
import * as pipes from "./pipes";
import * as liteComponent from "./liteComponent";
import * as filterComponent from "./filterComponents";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {AlertModalComponent, ConfirmComponent, AddCertModalComponent} from "./modals";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgOptionHighlightModule} from '@ng-select/ng-option-highlight';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {UiSwitchModule} from "ngx-ui-switch";
import {CryptographyModule} from "../cryptography";
import {SaveFileDirective} from "./directives";
import {DateFormatPipe} from "./pipes";
import {UserSessionFilterComponent} from "./filterComponents";
import {FileSaverModule} from "ngx-filesaver";
import {NgxMaskDirective} from "ngx-mask";

@NgModule({
    imports: [
        CryptographyModule,
        FontAwesomeModule,
        CommonModule,
        FileSaverModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgOptionHighlightModule,
        UiSwitchModule.forRoot({
            size: 'medium',
            color: '#ae0000',
        }),
        NgbModule,
        NgxMaskDirective
    ],
    providers: [
        services.AuthGuard,
        services.JwtService,
        services.ResourceService,
        services.ApiService,
        services.AccessRouteGuard,
        services.ValidatorService,
        services.DictionaryService,
        services.IconsService,
        services.UXService,
        liteComponent.FiasService,
        components.AlertService,
        components.AlertPanelService,
        services.VisitedService,
        services.StringDateAdapterService,
        services.GlobalDataProviderService,
        services.ProfileService
    ],
    declarations: [
        components.DatepickerComponent,
        components.DatetimepickerComponent,
        components.AlertPanelComponent,
        components.CustomSelectComponent,
        components.CustomSourceSelectComponent,
        components.CustomCheckboxComponent,
        components.JoinedMultiCheckboxComponent,
        components.AlertComponent,
        components.PaginatorComponent,
        components.ScrollBtnComponent,
        components.BigSelectComponent,
        components.BigSelectViewComponent,
        components.GoBackComponent,
        components.SvgIconComponent,
        components.UserComponent,
        directives.ShowAuthDirective,
        directives.CryptCheckStateDirective,
        directives.SortTableDirective,
        directives.AutoFocusDirective,
        directives.AutoFocusFlkDirective,
        directives.ShowElByAccessDirective,
        directives.ConfirmActionDirective,
        directives.InputFormatDirective,
        directives.HighlightDirective,
        directives.MoneyDirective,
        pipes.GetFirstWord,
        pipes.TruncatePipe,
        pipes.CurrencyRubPipe,
        liteComponent.FiasComponent,
        liteComponent.LoadingComponent,
        liteComponent.RqComponent,
        liteComponent.SortComponent,
        liteComponent.ValidComponent,
        liteComponent.AccordionCaretComponent,
        liteComponent.InputFileComponent,
        ConfirmComponent,
        AlertModalComponent,
        AddCertModalComponent,
        filterComponent.AuditFilterComponent,
        filterComponent.SearchInputFilterComponent,
        directives.SaveFileDirective,
        pipes.DateFormatPipe,
        filterComponent.UserSessionFilterComponent,
        components.UserFormComponent
    ],
    exports: [
        FontAwesomeModule,
        components.AlertComponent,
        components.SvgIconComponent,
        components.PaginatorComponent,
        pipes.GetFirstWord,
        pipes.TruncatePipe,
        pipes.CurrencyRubPipe,
        CommonModule,
        FormsModule,
        liteComponent.SortComponent,
        liteComponent.FiasComponent,
        liteComponent.LoadingComponent,
        liteComponent.RqComponent,
        liteComponent.ValidComponent,
        liteComponent.AccordionCaretComponent,
        liteComponent.InputFileComponent,
        ReactiveFormsModule,
        RouterModule,
        directives.SortTableDirective,
        directives.ShowElByAccessDirective,
        directives.ConfirmActionDirective,
        directives.AutoFocusDirective,
        directives.AutoFocusFlkDirective,
        directives.ShowAuthDirective,
        directives.CryptCheckStateDirective,
        directives.InputFormatDirective,
        directives.HighlightDirective,
        directives.MoneyDirective,
        components.DatepickerComponent,
        components.DatetimepickerComponent,
        components.AlertPanelComponent,
        components.CustomSelectComponent,
        components.CustomCheckboxComponent,
        components.JoinedMultiCheckboxComponent,
        components.CustomSourceSelectComponent,
        components.ScrollBtnComponent,
        components.BigSelectComponent,
        components.GoBackComponent,
        filterComponent.AuditFilterComponent,
        filterComponent.SearchInputFilterComponent,
        SaveFileDirective,
        UserComponent,
        DateFormatPipe,
        UserSessionFilterComponent,
        components.UserFormComponent
    ]
})
export class CoreModule {
  /**
   * Для lazy-module создается новый экземпляр сервиса. Данная конструкция необходима что сервис был один на все модули.
   * https://angular.io/guide/singleton-services
   */
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: services.UserService },
        { provide: services.SharedMenuService },
        { provide: services.TableService },
      ],
    };
  }
}

